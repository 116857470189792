<template>
  <div>
    <v-container
      v-if="init"
      class="init"
      fluid
      fill-height
      d-flex
      justify-center
    >
      <Loader></Loader>
    </v-container>
    <v-container v-else>
      <v-row>
        <v-dialog v-model="dialog" max-width="450">
          <v-card class="pa-2">
            <v-card-title class="headline">
              You have unsaved changes!
            </v-card-title>

            <v-card-text>
              You have unsaved changes! Are you sure you want to leave?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="dialog = false">
                {{ $t('Stay') }}
              </v-btn>

              <v-btn
                v-if="pageOfOrigin === 'timeline'"
                color="grey darken-1"
                text
                :to="{ name: 'timeline' }"
                @click="
                  ;(dialog = false),
                    navigateTo('timeline', (permissionToNavigate = true))
                "
              >
                {{ $t('Leave') }}
              </v-btn>

              <v-btn
                v-else
                color="grey darken-1"
                text
                :to="{ name: 'timeline' }"
                @click="
                  ;(dialog = false),
                    navigateTo('dashboard', (permissionToNavigate = true))
                "
              >
                {{ $t('Leave') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-col sm="12" md="6" lg="7">
          <h3 class="headline mb-4">
            <router-link
              v-if="pageOfOrigin === 'timeline'"
              :to="{ name: 'timeline' }"
            >
              {{ $t('Timeline') }}
            </router-link>
            <router-link v-else :to="{ name: 'dashboard' }">
              {{ $t('Dashboard') }}
            </router-link>

            /
            {{ $t('Edit campaign "%s"', original ? original.name : '') }}
          </h3>

          <v-card class="elevation-4">
            <v-card-text>
              <EditorBasic></EditorBasic>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <EditorChannels></EditorChannels>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <div class="pa-4">
                <v-row>
                  <v-col class="pt-2 pb-6">
                    <h2>{{ $t('Campaign schedule') }}</h2>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <div>
                      <p>
                        {{
                          $t(
                            'You can build your campaign schedule based on daily schedules or included or excluded TV programs.'
                          )
                        }}
                        <br />
                        {{
                          $t(
                            'A detailed schedule lets you tweak the campaign times in more detail.'
                          )
                        }}
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <v-tabs
                  :value="selectedTab"
                  centered
                  background-color="transparent"
                  class="pt-5"
                >
                  <v-tab href="#basic">Basic</v-tab>
                  <v-tab href="#detailed">Detailed</v-tab>
                  <v-tab-item value="basic">
                    <v-card-text>
                      <EditorSchedule></EditorSchedule>
                    </v-card-text>
                  </v-tab-item>
                  <v-tab-item value="detailed">
                    <v-card-text class="pt-9">
                      <DetailedScheduled
                        :disabled="!detailedScheduleEnabled"
                        @is-valid="detailedValidHandler"
                      ></DetailedScheduled>
                    </v-card-text>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <div class="pa-4">
                <v-row>
                  <v-col class="pt-2 pb-6">
                    <h2>{{ $t('Campaign graphics') }}</h2>
                  </v-col>
                </v-row>
                <EditorLBanner></EditorLBanner>
              </div>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <EditorImpressions></EditorImpressions>
            </v-card-text>
            <v-divider></v-divider>

            <v-card-text>
              <div class="pa-4">
                <InteractiveToggle class="pb-6"></InteractiveToggle>
                <AppCreative v-if="isInteractive"></AppCreative>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="12" md="6" lg="5">
          <h3 class="headline mb-4">{{ $t('Preview') }}</h3>

          <div class="sticky-float">
            <v-card class="preview-card elevation-4 mb-5">
              <EditorPreview />
            </v-card>

            <v-card class="action-card elevation-4 pa-6">
              <v-card-text
                v-if="!published"
                style="font-size: 18px; color: #E65C53;"
              >
                Campaign not yet ready!
              </v-card-text>

              <v-card-text
                v-if="published && suite_state !== 3"
                style="font-size: 18px; color: orange"
              >
                Campaign is ready
              </v-card-text>

              <v-card-text
                v-if="suite_state === 3"
                style="font-size: 18px; color: #4caf50;"
              >
                Campaign has gone live
              </v-card-text>

              <v-card-text
                v-if="
                  typeof startTime !== 'undefined' &&
                    startTime !== null &&
                    typeof endDay !== 'undefined' &&
                    endDay !== null &&
                    typeof startDay !== 'undefined' &&
                    startDay !== null &&
                    typeof endTime !== 'undefined' &&
                    endTime !== null
                "
                style="font-size: 18px; color: #37424A;"
              >
                {{ $t('The campaign is set to start on ') }}
                <span class="font-weight-black">
                  {{ formatDate(startDay) + $t(' at ') + startTime }}
                </span>
                {{ $t(' and end on ') }}
                <span class="font-weight-black">
                  {{ formatDate(endDay) + $t(' at ') + endTime + '.' }}
                </span>
              </v-card-text>

              <v-card-text
                v-else-if="
                  typeof startDay !== 'undefined' &&
                    startDay !== null &&
                    typeof endDay !== 'undefined' &&
                    endDay !== null
                "
                style="font-size: 18px; color: #37424A;"
              >
                {{ $t('The campaign is set to start on ') }}
                <span class="font-weight-black">
                  {{ formatDate(startDay) }}
                </span>
                {{ $t(' and end on ') }}
                <span class="font-weight-black">
                  {{ formatDate(endDay) + '.' }}
                </span>
              </v-card-text>

              <v-card-actions
                class="justify-space-between"
                style="padding: 16px"
              >
                <v-btn
                  color="success"
                  :loading="saving"
                  :disabled="!hasChanges || !isValid"
                  @click="saveDraft"
                >
                  {{ $t('Save changes') }}
                </v-btn>
                <v-btn
                  v-if="!published"
                  color="primary"
                  :loading="publishing"
                  :disabled="!readyForPublish || published"
                  @click="publishCampaign"
                >
                  {{ published ? $t('Ready') : $t('Ready up') }}
                </v-btn>

                <v-btn
                  v-if="published"
                  color="rgb(76, 175, 80)"
                  style="color: white"
                  :loading="publishing"
                  :disabled="!suiteIsApproved || suite_state === 3"
                  @click="goLive"
                >
                  {{ suite_state === 3 ? $t('Live') : $t('Go Live') }}
                </v-btn>
              </v-card-actions>

              <v-card-actions class="justify-end" style="padding: 16px">
                <v-btn
                  text
                  :loading="duplicating"
                  :disabled="hasChanges"
                  @click="duplicateCampaign"
                >
                  <v-icon small right>mdi-content-copy</v-icon>
                  {{ $t('Duplicate') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  :loading="deleting"
                  :disabled="publishing || hasChanges"
                  @click="deleteCampaign"
                >
                  <v-icon small right>mdi-delete</v-icon>
                  {{ $t('Delete ') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
@media #{map-get($display-breakpoints, 'md-and-up')}
  .sticky-float
    position: sticky
    top: 100px
</style>
<script>
import { mapGetters } from 'vuex'
import Loader from '@/components/common/Loader'
import moment from 'moment'

import EditorBasic from './editor/Basic'
import EditorChannels from './editor/ChannelsAndLength'
import EditorImpressions from './editor/Impressions'
import EditorLBanner from './editor/LBanner'
import EditorSchedule from './editor/Schedule'
import DetailedScheduled from './editor/DetailedScheduled'
import AppCreative from './editor/AppCreative'
import InteractiveToggle from './editor/InteractiveToggle'
import EditorPreview from './editor/Preview'

export default {
  components: {
    Loader,
    EditorBasic,
    EditorChannels,
    EditorImpressions,
    EditorLBanner,
    EditorSchedule,
    AppCreative,
    InteractiveToggle,
    DetailedScheduled,
    EditorPreview
  },

  props: {
    campaignId: {
      type: String,
      required: true
    },
    pageOfOrigin: {
      type: String,
      required: false,
      default: ''
    }
  },

  data: () => ({
    init: true,
    saving: false,
    publishing: false,
    duplicating: false,
    deleting: false,
    dialog: false,
    permissionToNavigate: false,
    detailedValid: true
  }),

  computed: {
    ...mapGetters(['appReady']),
    original() {
      return this.$store.state.campaigns.campaigns[this.campaignId]
    },
    submitting() {
      return this.saving || this.publishing
    },
    hasChanges() {
      return this.$store.getters['campaigns/editorHasChanges']
    },
    isValid() {
      return this.detailedValid
    },
    readyForPublish() {
      return this.$store.getters['campaigns/readyForPublish']
    },
    published() {
      return this.$store.getters['campaigns/campaignHasBeenPublished']
    },
    suiteIsApproved() {
      return this.$store.getters['campaigns/suiteStateIsApproved']
    },
    suite_state() {
      return this.$store.state.campaigns.editor.suite_state
    },
    isInteractive() {
      return this.$store.state.campaigns.editor.is_interactive
    },
    detailedScheduleEnabled() {
      return (
        this.$store.state.campaigns.editor.settings
          .detailed_schedule_edit_enabled || false
      )
    },
    selectedTab() {
      return this.detailedScheduleEnabled ? 'detailed' : 'basic'
    },
    startDay() {
      return this.$store.state.campaigns.editor.start_date
    },
    endDay() {
      return this.$store.state.campaigns.editor.end_date
    },
    startTime: {
      get() {
        let ds = this.$store.state.campaigns.editor.settings.daily_schedule
        return ds ? ds.start_time : null
      }
    },
    endTime: {
      get() {
        let ds = this.$store.state.campaigns.editor.settings.daily_schedule
        return ds ? ds.end_time : null
      }
    }
  },

  //this is to activate confirmation dialog on back button press
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges) {
      this.dialog = true
    } else {
      this.permissionToNavigate = true
    }

    if (this.permissionToNavigate == true) {
      next()
    } else {
      next(false)
    }
  },

  watch: {
    async appReady(status) {
      if (status) {
        await this.initEditor()
      }
    }
  },

  async created() {
    let me = this
    me.$debug('created ', me.campaignId, ' from ', me.pageOfOrigin)
    await me.initEditor()
  },

  methods: {
    async initEditor() {
      let me = this
      if (!me.appReady) {
        return false
      }
      await me.$store.dispatch('campaigns/loadCampaignToEditor', me.campaignId)
      me.init = false
    },

    async saveDraft() {
      let me = this
      me.saving = true
      try {
        me.$debug('saveDraft', me.form)
        await me.$store.dispatch('campaigns/updateCampaignInEditor')
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
        }
      }
      me.saving = false
    },

    async publishCampaign() {
      let me = this

      try {
        await me.$confirm('Publish this campaign?')
        me.publishing = true

        await me.$store.dispatch('campaigns/publishCampaign')

        me.publishing = false
        me.$toast({
          type: 'success',
          message: 'Campaign published'
        })
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
          me.publishing = false
        }
      }
    },

    async goLive() {
      let me = this

      try {
        await me.$confirm('Go Live with this campaign?')
        me.publishing = true

        await me.$store.dispatch('campaigns/goLive')

        me.publishing = false
        me.$toast({
          type: 'success',
          message: 'Campaign has gone live'
        })
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
          me.publishing = false
        }
      }
    },

    async duplicateCampaign() {
      let me = this
      me.duplicating = true
      try {
        me.$debug('duplicate', me.form)
        await me.$store.dispatch('campaigns/duplicateCampaign', this.campaignId)
        this.$toast({
          type: 'green',
          message: `Campaign duplicated`
        })
        await me.$redirect({
          name: 'dashboard'
        })
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
        }
      }
      me.duplicating = false
    },
    async deleteCampaign() {
      let me = this

      try {
        await me.$confirm('Do you really want to delete this campaign?')
        me.deleting = true
        await me.$store.dispatch('campaigns/deleteCampaign', this.campaignId)
        me.deleting = false

        me.$toast({
          type: 'success',
          message: 'Campaign deleted'
        })

        await me.$redirect({
          name: 'dashboard'
        })
      } catch (err) {
        if (err.message !== 'cancel') {
          me.$error(err)
          me.deleting = false
        }
      }
    },
    formatDate(date) {
      let formatedDate = moment(date).format('DD.MM.YYYY')
      formatedDate
      return formatedDate
    },

    async navigateTo(name) {
      let me = this
      try {
        await me.$redirect({
          name: name
        })
      } catch (err) {
        me.$debug(err)
      }
    },

    detailedValidHandler(value) {
      this.detailedValid = value
    }
  }
}
</script>
