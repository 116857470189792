<template>
  <v-form ref="form" v-model="formValid" @submit.prevent>
    <v-row>
      <v-col lg="3">
        <v-text-field
          v-model="date"
          :label="$t('Date')"
          filled
          type="date"
          required
          :rules="$rules.required"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col lg="2">
        <v-text-field
          v-model="start_time"
          :label="$t('From')"
          filled
          type="time"
          required
          :rules="$rules.required"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col lg="2">
        <v-text-field
          v-model="end_time"
          :label="$t('To')"
          filled
          type="time"
          required
          :rules="$rules.required"
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col lg="2">
        <v-btn
          :disabled="!formValid"
          color="green"
          text
          :loading="saving"
          type="submit"
          @click="addSchedule()"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'
import moment from 'moment'

export default {
  mixins: [FormMixin],
  props: {
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    row: {},
    formValid: false,
    saving: false,
    date: null,
    start_time: null,
    end_time: null
  }),
  computed: {},
  methods: {
    async addSchedule() {
      let me = this
      me.saving = true

      let startTime = moment(`${me.date} ${me.start_time}`)
      let endTime = moment(`${me.date} ${me.end_time}`)

      await me.$store.dispatch('campaigns/createScheduleRow', {
        start_time: startTime,
        end_time: endTime
      })
      this.$refs.form.reset()

      me.saving = false
    }
  }
}
</script>
