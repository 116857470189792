<template>
  <div>
    <v-row v-if="!detailedScheduleEnabled && hasAnyBasicSchedules">
      <v-col class="red--text">
        NOTE: Modifying detailed times will override the original campaign
        scheduling options.
      </v-col>
      <v-col v-if="!detailedScheduleEnabled">
        <v-btn outlined @click="editScheduleHandler">
          <v-icon class="mr-3">mdi-pencil</v-icon>
          {{ $t('Edit') }}
        </v-btn>
      </v-col>
    </v-row>
    <EditorAddScheduleRow :disabled="!detailedScheduleEnabled" />
    <v-form ref="rowForm" v-model="validForm">
      <EditorScheduleRow
        v-for="row in schedules"
        :key="row.id"
        :row="row"
        :disabled="!detailedScheduleEnabled"
      />
    </v-form>
  </div>
</template>
<script>
import _ from 'lodash'
import GlobalMixin from '@/mixins/GlobalMixin'
import FormMixin from '@/mixins/FormMixin'
import AuthMixin from '@/mixins/AuthMixin'
import moment from 'moment'
import EditorScheduleRow from './ScheduleRow'
import EditorAddScheduleRow from './AddScheduleRow'

export default {
  components: {
    EditorScheduleRow,
    EditorAddScheduleRow
  },
  mixins: [FormMixin, AuthMixin, GlobalMixin],
  data: () => ({
    validForm: false
  }),
  computed: {
    schedules: {
      get() {
        return this.$store.getters['campaigns/schedule']
      },
      set(value) {
        let mapped = _.map(value, sch => {
          sch.schedule_object.start_time = moment(
            `${sch.date} ${sch.start_time}`
          )
          sch.schedule_object.end_time = moment(`${sch.date} ${sch.end_time}`)
          return sch.schedule_object
        })
        this.$store.commit('campaigns/set', [
          'scheduleEditor',
          _.keyBy(mapped, 'id')
        ])
      }
    },
    detailedScheduleEnabled() {
      return this.$store.state.campaigns.editor.settings
        .detailed_schedule_edit_enabled
    },
    hasAnyBasicSchedules() {
      let hasDailyScheduleSet = !_.isEmpty(
        this.$store.state.campaigns.editor.settings.daily_schedule
      )
      let hasIncludedTvPrograms = !_.isEmpty(
        this.$store.state.campaigns.editor.included_tv_programs
      )
      let hasExcludedTvPrograms = !_.isEmpty(
        this.$store.state.campaigns.editor.excluded_tv_programs
      )
      return (
        hasDailyScheduleSet || hasIncludedTvPrograms || hasExcludedTvPrograms
      )
    },
    campaignStartDate() {
      return this.$store.state.campaigns.editor.start_date
    },
    campaignEndDate() {
      return this.$store.state.campaigns.editor.end_date
    }
  },
  watch: {
    validForm(value) {
      this.$emit('is-valid', value)
    },
    campaignStartDate() {
      //Workaround for missing error messages on UI when campaign date changes
      this.$refs.rowForm.validate()
    },
    campaignEndDate() {
      //Workaround for missing error messages on UI when campaign date changes
      this.$refs.rowForm.validate()
    }
  },
  methods: {
    date(value) {
      return moment(value).format('YYYY-MM-DD')
    },
    time(value) {
      return moment(value).format('HH:mm')
    },
    async editScheduleHandler() {
      let me = this
      try {
        await me.$confirm(
          'You have set some basic schedule options like Daily schedule or Included/Excluded TV programs. Those settings are deleted if the detailed schedule is edited. Do you want to continue?',
          'Confirm edit'
        )
        this.$store.commit('campaigns/set', [
          'editor.settings.daily_schedule',
          {}
        ])
        this.$store.commit('campaigns/set', ['editor.included_tv_programs', {}])
        this.$store.commit('campaigns/set', ['editor.excluded_tv_programs', {}])
        this.$store.commit('campaigns/set', [
          'editor.settings.detailed_schedule_edit_enabled',
          true
        ])
      } catch (err) {
        me.$error(err)
      }
    },
    async saveScheduleHandler() {
      //TODO: maybe? await dispatch('updateScheduleInEditor')
    }
  }
}
</script>
