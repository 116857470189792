<template>
  <v-row>
    <v-col lg="3">
      <v-text-field
        v-model="date"
        :label="$t('Date')"
        filled
        type="date"
        :disabled="disabled"
        :rules="[dateValid]"
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-text-field
        v-model="start_time"
        :label="$t('From')"
        filled
        type="time"
        :disabled="disabled"
        required
        :rules="[startTimeValid]"
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-text-field
        v-model="end_time"
        :label="$t('To')"
        filled
        type="time"
        :disabled="disabled"
        required
        :rules="[endTimeValid]"
      ></v-text-field>
    </v-col>
    <v-col lg="2">
      <v-btn :disabled="disabled" color="red" text @click="removeSchedule()">
        Remove
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import _ from 'lodash'
import FormMixin from '@/mixins/FormMixin'
import moment from 'moment'

export default {
  mixins: [FormMixin],
  props: {
    row: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    edit: true
  }),
  computed: {
    dateValid() {
      if (moment(this.date).isBefore(moment(this.campaignStartDate))) {
        return 'The campaign has not started on the given day.'
      }
      if (moment(this.date).isAfter(moment(this.campaignEndDate))) {
        return 'The campaign has ended on the given day.'
      }
      return true
    },
    startTimeValid() {
      if (this.start_time > this.end_time) {
        return 'The start time cannot be later than the end time.'
      }
      return true
    },
    endTimeValid() {
      if (this.end_time < this.start_time) {
        return 'The end time cannot be earlier than the start time.'
      }
      return true
    },
    campaignStartDate() {
      return this.$store.state.campaigns.editor.start_date
    },
    campaignEndDate() {
      return this.$store.state.campaigns.editor.end_date
    },
    date: {
      get() {
        return moment(this.row.start_time).format('YYYY-MM-DD')
      },
      set(value) {
        let startTime = moment(`${value} ${this.start_time}`)
        let endTime = moment(`${value} ${this.end_time}`)

        this.row.start_time = startTime
        this.row.end_time = endTime
        this.$store.commit('campaigns/mapSet', [
          'campaigns',
          this.row.id,
          this.row
        ])
      }
    },
    start_time: {
      get() {
        return moment(this.row.start_time).format('HH:mm')
      },
      set(value) {
        let startTime = moment(`${this.date} ${value}`)
        this.row.start_time = startTime
        this.$store.commit('campaigns/mapSet', [
          'campaigns',
          this.row.id,
          this.row
        ])
      }
    },
    end_time: {
      get() {
        return moment(this.row.end_time).format('HH:mm')
      },
      set(value) {
        let endTime = moment(`${this.date} ${value}`)
        this.row.end_time = endTime
        this.$store.commit('campaigns/mapSet', [
          'campaigns',
          this.row.id,
          this.row
        ])
      }
    }
  },
  methods: {
    async removeSchedule() {
      let me = this
      try {
        //TODO: if not found?
        let schedule = _.get(this.$store.state.campaigns.schedule, this.row.id)
        await me.$confirm(
          'Do you really want to delete schedule "' +
            moment(schedule.start_time).format('DD.MM.YYYY HH:mm') +
            ' - ' +
            moment(schedule.end_time).format('HH:mm') +
            '" ?',
          'Confirm delete'
        )
        await me.$store.dispatch('campaigns/deleteItemInSchedule', this.row.id)
      } catch (err) {
        me.$error(err)
      }
    }
  }
}
</script>
