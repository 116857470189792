<template>
  <div>
    <v-row>
      <v-col>
        <h3>{{ $t('L-Banner image') }}</h3>
      </v-col>
    </v-row>
    <v-row v-if="preview">
      <v-col>
        <v-img
          :src="preview"
          :max-width="300"
          :aspect-ratio="l_banner.width / l_banner.height"
        ></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn outlined large @click="dialog = true">
          {{ $t('Upload graphic') }}
        </v-btn>
      </v-col>
      <v-dialog v-model="dialog" fullscreen>
        <v-card>
          <v-toolbar dark color="grey darken-4">
            <v-btn dark text @click="cancelAndClose">
              {{ $t('Cancel') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>
              {{ $t('L-Banner upload') }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>

          <!-- l-banner upload step 2 -->
          <div v-if="images.original">
            <v-container v-if="uploading" class="text-center">
              <v-progress-linear
                :value="progress"
                height="30"
              ></v-progress-linear>
            </v-container>
            <v-container v-else class="text-center">
              <h2>
                Confirm that you do not see any
                <span class="red--text">red stripes</span>
                in the inner border of your L-Banner graphic.
              </h2>
              <p>
                If you see red stripes, then your
                <b>
                  L-Banner vertical width is not
                  {{ l_banner.vertical_width }} px
                </b>
                or your
                <b>
                  L-Banner horizontal height is not
                  {{ l_banner.horizontal_height }} px.
                </b>
              </p>

              <div class="d-flex align-center justify-center">
                <v-btn text class="mr-4" @click="resetUpload">
                  {{ $t('Back') }}
                </v-btn>

                <v-btn color="primary" large @click="processAndUpload">
                  {{ $t('Confirm and continue') }}
                </v-btn>
              </div>
            </v-container>

            <v-container fluid full-height class="d-flex justify-center">
              <div class="verify-wrapper">
                <v-img
                  class="verify-overlay"
                  src="@/assets/l-banner-overlay.png"
                  :max-width="l_banner.width"
                  :min-width="l_banner.width / 2"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
                <v-img
                  class="verify-front"
                  :src="images.original.src"
                  :max-width="l_banner.width"
                  :min-width="l_banner.width / 2"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
                <v-img
                  class="verify-bg"
                  src="@/assets/l-banner-verify-bg.png"
                  :max-width="l_banner.width"
                  :min-width="l_banner.width / 2"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
              </div>
            </v-container>
          </div>

          <!-- l-banner upload step 1 -->
          <div v-else>
            <v-container class="text-center">
              <p>
                Upload a
                <b>PNG-file</b>
                with dimensions of
                <b>width {{ l_banner.width }}px</b>
                and
                <b>height {{ l_banner.height }}px</b>
                following the guideline shown below.
              </p>

              <input
                ref="fileInput"
                type="file"
                class="d-none"
                accept=".png,image/png"
                @change="selectFile"
              />
              <v-btn
                color="primary"
                large
                @click.native="$refs.fileInput.click()"
              >
                {{ $t('Select file...') }}
                <v-icon right>mdi-file-upload</v-icon>
              </v-btn>
            </v-container>

            <v-container fluid full-height class="d-flex justify-center">
              <v-img
                src="@/assets/l-banner-guide.png"
                max-width="1280"
                min-width="640"
                aspect-ratio="1.78"
                contain
              ></v-img>
            </v-container>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<style lang="sass" scoped>
.verify-wrapper
  position: relative
  width: 100%
  min-width: 640px
  min-height: 360px
  max-width: 1280px
  max-height: 720px
  .v-image
    bottom: 0
    left: 0
  .verify-screen
    background-color: #2C313A
  .verify-overlay
    position: absolute
    width: 100%
    z-index: 1
  .verify-front
    position: absolute
    width: 100%
    z-index: 1
  .verify-bg
    position: relative
    z-index: 0
</style>
<script>
import _ from 'lodash'

export default {
  data: () => ({
    dialog: false,
    l_banner: {
      width: 1280,
      height: 720,
      vertical_width: 293,
      horizontal_height: 165,
      thumb_width: 500
    },
    images: {
      original: null,
      l_vertical: null,
      l_horizontal: null
    },
    uploading: false,
    progress: 0
  }),

  computed: {
    assetsByType() {
      return this.$store.getters['campaigns/editorAssetsByType']
    },
    preview() {
      return _.get(this.assetsByType, '[l-banner-bottom-500]._uri', false)
    }
  },

  mounted() {
    let me = this
    me.resetUpload()
    // TODO: Remove this debug
    // me.dialog = true
  },

  methods: {
    cancelAndClose() {
      let me = this
      me.dialog = false
      me.resetUpload()
    },

    resetUpload() {
      let me = this
      me.uploading = false
      me.progress = 0
      me.images = {
        original: null,
        l_vertical: null,
        l_horizontal: null
      }
    },

    selectFile() {
      let me = this
      const input = me.$refs.fileInput
      if (!input.files || !input.files[0]) {
        return me.$error('Unable to access file')
      }
      me.$debug('filereader', input.files[0])
      const reader = new FileReader()
      reader.onload = () => {
        me.images.original = new Image()
        me.images.original.onload = () => {
          me.validateOriginal()
        }
        me.images.original.src = reader.result
      }
      reader.readAsDataURL(input.files[0])
    },

    validateOriginal() {
      let me = this
      let original = me.images.original
      try {
        if (
          original.width !== me.l_banner.width ||
          me.l_banner.height !== 720
        ) {
          throw new Error(
            `Image dimensions are wrong, image should be ${me.l_banner.width}x${me.l_banner.height} px, but you provided an image of ${original.width}x${original.height} px.`
          )
        }
      } catch (err) {
        me.$error(err)
        me.images.original = null
      }
      me.$debug('validateOriginal', original.width, original.height)
    },

    async processAndUpload() {
      let me = this
      try {
        me.uploading = true
        // upload original

        me.$debug('processAndUpload - generate slices')
        const slices = await me.slicesFromOriginal()
        me.$debug('slices', slices)
        me.progress = 20

        // upload original
        me.$debug('processAndUpload - upload original')
        await me.$store.dispatch('campaigns/uploadBlob', {
          filename: `l-banner-bottom-original.png`,
          type: 'l-banner-bottom-original',
          blob: slices.l_original
        })
        me.progress = 40

        // upload thumbnail
        me.$debug('processAndUpload - generate thumb')
        let thumb = await me.thumbOriginal(me.l_banner.thumb_width)
        me.$debug('processAndUpload - upload thumb', thumb)
        await me.$store.dispatch('campaigns/uploadBlob', {
          filename: `l-banner-bottom-${me.l_banner.thumb_width}.png`,
          type: `l-banner-bottom-${me.l_banner.thumb_width}`,
          blob: thumb
        })
        me.progress = 60

        // upload vertical slice
        me.$debug('processAndUpload - upload vertical slice', slices.l_vertical)
        await me.$store.dispatch('campaigns/uploadBlob', {
          filename: `l-banner-bottom-left.png`,
          type: 'l-banner-bottom-left',
          blob: slices.l_vertical
        })
        me.progress = 80

        // upload horizontal slice
        me.$debug(
          'processAndUpload - upload horizontal slice',
          slices.l_horizontal
        )
        await me.$store.dispatch('campaigns/uploadBlob', {
          filename: `l-banner-bottom-right.png`,
          type: 'l-banner-bottom-right',
          blob: slices.l_horizontal
        })
        me.progress = 100

        await me.$store.dispatch('campaigns/loadAssets')

        me.$toast({ type: 'success', message: `L-Banner added to campaign` })

        me.cancelAndClose()
      } catch (err) {
        me.$error(err)
      }
    },

    async slicesFromOriginal() {
      let me = this
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = me.l_banner.width
      canvas.height = me.l_banner.height
      ctx.drawImage(me.images.original, 0, 0)

      const l_original = await me.imageDataToBlob(
        ctx.getImageData(0, 0, canvas.width, canvas.height)
      )
      let verticalImageData = ctx.getImageData(
        0,
        0,
        me.l_banner.vertical_width,
        me.l_banner.height
      )
      me.$debug('verticalImageData', verticalImageData)
      const l_vertical = await me.imageDataToBlob(verticalImageData)
      me.progress = 10

      const horizontalX = me.l_banner.vertical_width
      const horizontalY = me.l_banner.height - me.l_banner.horizontal_height
      const horizontalWidth = me.l_banner.width - me.l_banner.vertical_width
      const horizontalHeight = me.l_banner.horizontal_height
      me.$debug('horizontalImageCoordinates', {
        horizontalX,
        horizontalY,
        horizontalWidth,
        horizontalHeight
      })
      let horizontalImageData = ctx.getImageData(
        horizontalX,
        horizontalY,
        horizontalWidth,
        horizontalHeight
      )
      me.$debug('horizontalImageData', horizontalImageData)
      const l_horizontal = await me.imageDataToBlob(horizontalImageData)
      return {
        l_vertical,
        l_horizontal,
        l_original
      }
    },

    // https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
    // http://jsfiddle.net/sp3c7jeq/
    async thumbOriginal(width) {
      let me = this
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')

      // -- stepped scaling --
      canvas.width = width // destination canvas size
      canvas.height =
        (canvas.width * me.images.original.height) / me.images.original.width

      ctx.drawImage(me.images.original, 0, 0, canvas.width, canvas.height)
      return await me.imageDataToBlob(
        ctx.getImageData(0, 0, canvas.width, canvas.height)
      )
    },

    async imageDataToBlob(imageData) {
      const canvas = document.createElement('canvas')
      canvas.width = imageData.width
      canvas.height = imageData.height
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'black'
      ctx.fill()
      ctx.putImageData(imageData, 0, 0)
      return await new Promise(resolve => canvas.toBlob(resolve, 'image/png'))
    }
  }
}
</script>
