<template>
  <div class="pa-4">
    <v-row>
      <v-col class="pt-2 pb-6">
        <h2>{{ $t('Campaign channels & length') }}</h2>
      </v-col>
    </v-row>
    <v-form v-model="validForm">
      <v-row>
        <v-col>
          <p>{{ $t('Select the TV channel where the campaign is active') }}</p>

          <v-chip-group
            v-model="selectedChannelIndexes"
            column
            multiple
            class="channel-toggle"
          >
            <v-chip
              v-for="channel in organisationChannels"
              :key="channel.id"
              outlined
              class="channel-chip"
              active-class="selected-channel"
              label
            >
              <img
                v-if="channel.logo_url"
                :src="channel.logo_url"
                :alt="channel.name"
              />

              <div>
                {{ channel.name }}
              </div>
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="isDraft" lg="6">
          <v-select
            v-model="lengthDays"
            :items="campaignLengths"
            :label="$t('Campaign length')"
            filled
          ></v-select>
        </v-col>
        <v-col lg="6">
          <v-text-field
            v-model="startDay"
            :label="$t('Start day')"
            filled
            type="date"
            :disabled="!isDraft"
          ></v-text-field>
        </v-col>
        <v-col v-if="isPublished || isRunning" lg="6">
          <v-text-field
            v-model="endDay"
            :label="$t('End day')"
            filled
            type="date"
            :rules="[endDayValid]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<style lang="sass" scoped>
@import '@/scss/variables.scss'

.channel-toggle
  .channel-chip
    height: 80px
    width: 100px
    border-radius: 20px !important
    border-width: 2px
    margin: 4px
    opacity: 0.8
    img
      display: block
      max-height: 32px
      max-width: 80px
      margin-bottom: 15px
    div
      position: absolute
      bottom: 5px
    span
      display: inline-block
    &::v-deep .v-chip__content
      width: 100%
      display: flex
      justify-content: center
      flex-direction: column
    &.selected-channel
      opacity: 1
      border-color: map-get($digita, 'pink')
</style>
<script>
import _ from 'lodash'
import moment from 'moment'
import FormMixin from '@/mixins/FormMixin'

export default {
  mixins: [FormMixin],
  data: () => ({
    validForm: false,
    campaignLengths: [
      { text: 'One week', value: 7 },
      { text: 'Two weeks', value: 14 },
      { text: 'Three weeks', value: 21 },
      { text: 'Four weeks', value: 28 },
      { text: 'Five weeks', value: 35 },
      { text: 'Seven weeks', value: 42 },
      { text: 'Eight weeks', value: 49 }
    ]
  }),
  computed: {
    organisationChannels() {
      return this.$store.getters['organisation/channels']
    },
    organisationChannelIds() {
      return _.keyBy(this.organisationChannels, 'id')
    },
    includedAndExcludedTVPrograms() {
      return _.concat(
        this.$store.getters['campaigns/includedTVPrograms'],
        this.$store.getters['campaigns/excludedTVPrograms']
      )
    },

    channels() {
      return this.$store.state.campaigns.editor.channels
    },
    isDraft() {
      return this.$store.state.campaigns.editor.state === 'draft'
    },
    isPublished() {
      return this.$store.state.campaigns.editor.state === 'published'
    },
    isRunning() {
      return this.$store.state.campaigns.editor.state === 'running'
    },
    basicScheduleInUse() {
      return !this.$store.state.campaigns.editor.settings
        .detailed_schedule_edit_enabled
    },
    selectedChannelIndexes: {
      get() {
        let me = this
        return me.channels.map(channelId => {
          return _.findIndex(me.organisationChannels, ['id', channelId])
        })
      },
      set(indexes) {
        let me = this
        me.$debug('index', typeof indexes, indexes)
        let selectedChannels = []
        _.each(indexes, index => {
          selectedChannels.push(_.get(me.organisationChannels, `[${index}].id`))
        })

        me.$store.commit('campaigns/set', ['editor.channels', selectedChannels])
      }
    },
    lengthDays: {
      get() {
        return this.$store.state.campaigns.editor.settings.campaign_length_days
      },
      set(value) {
        this.$store.commit('campaigns/set', [
          'editor.settings.campaign_length_days',
          value
        ])
        if (this.isDraft) {
          this.endDay = moment(this.startDay)
            .add(this.lengthDays - 1, 'd')
            .format('YYYY-MM-DD')
        }
        if (this.basicScheduleInUse) {
          this.$store.dispatch('campaigns/createDailySchedule')
        }
      }
    },
    startDay: {
      get() {
        return this.$store.state.campaigns.editor.start_date
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.start_date', value])
        if (this.isDraft) {
          this.endDay = moment(value)
            .add(this.lengthDays - 1, 'd')
            .format('YYYY-MM-DD')
        }

        if (this.basicScheduleInUse) {
          this.$store.dispatch('campaigns/createDailySchedule')
        }
      }
    },
    endDay: {
      get() {
        return this.$store.state.campaigns.editor.end_date
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.end_date', value])

        if (this.basicScheduleInUse) {
          this.$store.dispatch('campaigns/createDailySchedule')
        }
      }
    },
    endDayValid() {
      if (this.startDay >= this.endDay) {
        return 'The end day cannot be before the start day.'
      }
      return true
    }
  },
  watch: {
    selectedChannelIndexes: async function(indexes, oldIndexes) {
      //If included or excluded TV programs contains programs from
      //channels which were just unselected, ask confirmation from user
      //and remove programs from those settings if user confirms the action.
      //Otherwise set back the channel unselected
      let me = this

      let selectedChannels = []
      _.each(indexes, index => {
        selectedChannels.push(_.get(me.organisationChannels, `[${index}].id`))
      })

      let selectedEpgChannelIds = _.map(
        selectedChannels,
        ch => this.organisationChannelIds[ch].epg_channel_id
      )

      var programChannels = _.map(me.includedAndExcludedTVPrograms, p => ({
        epg_channel_id: parseInt(p['@channel']),
        name: p['title'],
        id: p['@id']
      }))

      var programsWithoutChannel = _.filter(
        programChannels,
        pch => _.includes(selectedEpgChannelIds, pch.epg_channel_id) == false
      )

      if (!_.isEmpty(programsWithoutChannel)) {
        var programNames = _.map(programsWithoutChannel, p => p.name['#text'])
        try {
          await me.$confirm(
            'Following TV programs will be removed from included/excluded TV programs of this campaign if you unselect the channel: ' +
              _.join(programNames, ', '),
            'Confirm channel unselect'
          )
          me.$store.dispatch(
            'campaigns/removeTVProgramsFromEditor',
            _.map(programsWithoutChannel, p => p.id)
          )
        } catch (err) {
          if (err.message == 'cancel') {
            me.selectedChannelIndexes = oldIndexes
          }
        }
      }
    }
  }
}
</script>
