<template>
  <div class="pa-4">
    <v-row>
      <v-col class="pt-2 pb-6">
        <h2>{{ $t('Campaign impressions') }}</h2>
      </v-col>
    </v-row>
    <v-row v-if="!isEditable">
      <v-col>
        <p>
          {{
            $t(
              'Impression amounts and limitations are defined by Digita as you lack authorization.'
            )
          }}
        </p>
      </v-col>
    </v-row>
    <v-form>
      <v-row>
        <v-col lg="6">
          <v-text-field
            v-model.number="settings.total_impressions"
            :label="$t('Total impressions')"
            filled
            :disabled="!isEditable"
            :required="isEditable"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <v-text-field
            v-model.number="settings.min_time_between_ads_min"
            :label="$t('Min time between ad views')"
            filled
            :disabled="!isEditable"
            :required="isEditable"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <v-text-field
            v-model.number="settings.max_daily_imp_per_device"
            :label="$t('Max daily impressions per device')"
            filled
            :disabled="!isEditable"
            :required="isEditable"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col lg="6">
          <v-text-field
            v-model.number="settings.max_weekly_imp_per_device"
            :label="$t('Max weekly impressions per device')"
            filled
            :disabled="!isEditable"
            :required="isEditable"
            type="number"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'
import AuthMixin from '@/mixins/AuthMixin'

export default {
  mixins: [FormMixin, AuthMixin],
  computed: {
    settings: {
      get() {
        return this.$store.state.campaigns.editor.settings
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.settings', value])
      }
    },
    isEditable() {
      const isDraft = this.$store.state.campaigns.editor.state === 'draft'
      const isOrgAdmin = this.$store.getters['user/isOrgAdmin']
      const alwaysEditable = this.$store.state.campaigns.editor
        .editable_impressions

      return isDraft && (alwaysEditable || isOrgAdmin)
    }
  }
}
</script>
