<template>
  <v-tabs centered background-color="transparent">
    <v-tab href="#lbanner">L Banner</v-tab>
    <v-tab href="#interaction">Interaction</v-tab>

    <v-tab-item value="lbanner">
      <v-card-text>
        <v-row justify="center">
          <v-container class="justify-center">
            <div class="preview-wrapper">
              <v-img
                class="preview-tv-image"
                src="@/assets/preview-tv.png"
                :aspect-ratio="l_banner.width / l_banner.height"
                contain
              ></v-img>
              <div v-if="l_banner_image">
                <v-img
                  class="preview-l-banner-image"
                  :src="l_banner_image"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
                <v-img
                  class="preview-program-image"
                  src="@/assets/preview-program-image.png"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
              </div>
              <div v-else>
                <v-img
                  class="preview-darken-overlay-image"
                  src="@/assets/preview-darken-overlay.png"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
                <v-img
                  class="preview-darken-overlay-image"
                  src="@/assets/preview-darken-overlay.png"
                  :aspect-ratio="l_banner.width / l_banner.height"
                  contain
                ></v-img>
                <p class="no-preview-text">
                  {{ $t('Please add an L Banner image') }}
                </p>
              </div>
            </div>
          </v-container>
        </v-row>
        <v-row justify="center">
          {{
            $t('Here you can see how your ad campaign will be seen in the TV.')
          }}
        </v-row>
      </v-card-text>
    </v-tab-item>
    <v-tab-item value="interaction">
      <v-card-text>
        <v-row justify="center">
          <v-container class="justify-center">
            <div class="preview-wrapper">
              <v-img
                class="preview-tv-image"
                src="@/assets/preview-tv.png"
                :aspect-ratio="l_banner.width / l_banner.height"
                contain
              ></v-img>
              <v-img
                class="preview-darken-overlay-image"
                src="@/assets/preview-darken-overlay.png"
                :aspect-ratio="l_banner.width / l_banner.height"
                contain
              ></v-img>
              <v-img
                class="preview-app-image"
                :src="app_image"
                :aspect-ratio="l_banner.width / l_banner.height"
                contain
              ></v-img>
            </div>
          </v-container>
        </v-row>
        <!-- TODO: Better way to reserve space for text, to get same height for L Bannner, Interaction and SMS tabs? -->
        <v-row justify="center" style="opacity: 0">-</v-row>
      </v-card-text>
    </v-tab-item>
  </v-tabs>
</template>
<style lang="sass" scoped>
.preview-wrapper
  position: relative
  display: flex
  margin-left: auto
  margin-right: auto
  width: 500px
  .preview-l-banner-image
    position: absolute
    left: 43px
    top: 10px
    width: 414px
    z-index: 1
  .preview-darken-overlay-image
    position: absolute
    left: 43px
    top: 10px
    width: 414px
    z-index: 1
  .preview-program-image
    position: absolute
    left: 136px
    top: 10px
    width: 322px
    z-index: 1
  .preview-tv-image
    position: relative
    z-index: 0
    width: 500px
  .preview-phone-image
    position: relative
    z-index: 0
    width: 500px
  .preview-app-image
    position: absolute
    left: 186px
    top: 11px
    width: 410px
    z-index: 1
  .preview-message-card
    position: absolute
    left: 183px
    top: 90px
    width: 140px
    z-index: 1
  .preview-message-text
    font-family: Helvetica
    font-style: normal
    font-weight: normal
    font-size: 9px
    line-height: 10px
    color: black
    padding: 5px
    margin: 2px
    max-height: 180px
  .preview-delivered-text
    font-family: Helvetica
    font-style: normal
    font-weight: normal
    font-size: 7px
    line-height: 6px
    color: gray
    margin-left: 6px
    margin-top: 4px
  .bottom-gradient
    background-image: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, transparent 30px)

  .no-preview-text
    color: var(--v-primary-lighten2)
    position: absolute
    top: 45%
    left: 50%
    transform: translate(-50%, -50%)
    z-index: 2
    font-size: 18px
    select: none
</style>
<script>
import _ from 'lodash'
export default {
  data: () => ({
    l_banner: {
      width: 1280,
      height: 720,
      vertical_width: 293,
      horizontal_height: 165,
      thumb_width: 500
    },
    images: {
      original: null,
      l_vertical: null,
      l_horizontal: null
    }
  }),
  computed: {
    assetsByType() {
      return this.$store.getters['campaigns/editorAssetsByType']
    },
    tv_image() {
      return require('@/assets/preview-tv.png')
    },
    l_banner_image() {
      return _.get(this.assetsByType, '[l-banner-bottom-500]._uri', '')
    },
    app_image() {
      return _.get(this.assetsByType, '[app-creative-300]._uri', '')
    }
  },
  methods: {}
}
</script>
