<template>
  <div>
    <v-row class="align-center pt-6">
      <v-col md="auto">
        <h2>{{ $t('1. Daily schedule') }}</h2>
      </v-col>
      <v-col>
        <v-btn
          outlined
          :disabled="this.$store.state.campaigns.editor.start_date == null"
          @click="editDailySchedule"
        >
          <v-icon class="mr-3">mdi-pencil</v-icon>
          {{ $t('Edit') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="startTime != null">
        <v-chip>From {{ startTime }} to {{ endTime }}</v-chip>
      </v-col>
      <v-col v-else>
        {{ $t('No daily schedule set yet.') }}
      </v-col>
    </v-row>

    <TVProgramSelector
      type="included"
      :title="$t('2. Included TV programs')"
      class="pt-6"
    />

    <TVProgramSelector
      type="excluded"
      :title="$t('3. Excluded TV programs')"
      class="pt-6"
    />

    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('Set a daily schedule') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validForm">
            <v-row>
              <v-col lg="2">
                <v-text-field
                  v-model="from"
                  :label="$t('From')"
                  filled
                  type="time"
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
              <v-col lg="2">
                <v-text-field
                  v-model="to"
                  :label="$t('To')"
                  filled
                  type="time"
                  required
                  :rules="$rules.required"
                ></v-text-field>
              </v-col>
              <v-col>
                {{
                  $t(
                    'Set a daily base schedule for your campaign. You can edit the schedule in more detail later on.'
                  )
                }}
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-if="hasDailySchedule"
            outlined
            color="primary"
            class="mr-5"
            @click="removeDailySchedule"
          >
            <v-icon>mdi-delete</v-icon>
            Remove
          </v-btn>

          <v-btn color="grey darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            large
            :disabled="!validForm"
            @click="saveSchedule"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import FormMixin from '@/mixins/FormMixin'
import TVProgramSelector from './TVProgramSelector'
import _ from 'lodash'

export default {
  components: {
    TVProgramSelector
  },
  mixins: [FormMixin],
  props: {},
  data: () => ({
    dialog: false,
    dialogIncludeTVPrograms: false,
    validForm: false,
    validFormIncludeTVPrograms: false,
    from: '09:00',
    to: '19:00'
  }),
  computed: {
    startTime: {
      get() {
        let ds = this.$store.state.campaigns.editor.settings.daily_schedule
        return ds ? ds.start_time : null
      }
    },
    endTime: {
      get() {
        let ds = this.$store.state.campaigns.editor.settings.daily_schedule
        return ds ? ds.end_time : null
      }
    },
    hasDetailedScheduleEdited: {
      get() {
        return this.$store.state.campaigns.editor.settings
          .detailed_schedule_edit_enabled
      }
    },
    hasDailySchedule() {
      return !_.isEmpty(
        this.$store.state.campaigns.editor.settings.daily_schedule
      )
    }
  },
  watch: {},
  methods: {
    async confirmEditIfNecessary() {
      let me = this
      if (!me.hasDetailedScheduleEdited) return true
      try {
        await me.$confirm(
          'You have edited detailed schedule. The detailed schedule is reset if the basic schedule options are edited. Do you want to continue?',
          'Confirm edit'
        )
      } catch (err) {
        me.$error(err)
        return false
      }
      return true
    },

    async editDailySchedule() {
      let me = this
      if (await me.confirmEditIfNecessary()) {
        me.openDialog()
      }
    },
    async removeDailySchedule() {
      let me = this

      try {
        await me.$confirm(
          'Do you want to delete daily schedule? Also Excluded TV programs will be deleted if any.',
          'Confirm delete'
        )
        me.$store.dispatch('campaigns/removeDailySchedule')

        me.$toast({
          type: 'success',
          message: 'Daily schedule deleted'
        })

        me.dialog = false
      } catch (err) {
        me.$error(err)
        return false
      }
    },
    openDialog() {
      let me = this
      me.dialog = true
    },
    async saveSchedule() {
      let me = this

      me.$store.commit('campaigns/set', [
        'editor.settings.daily_schedule',
        {
          start_time: me.from,
          end_time: me.to
        }
      ])
      me.$store.dispatch('campaigns/createDailySchedule')

      me.$store.commit('campaigns/set', [
        'editor.settings.detailed_schedule_edit_enabled',
        false
      ])
      me.dialog = false
    }
  }
}
</script>
