<template>
  <div>
    <v-row class="align-center">
      <v-col md="auto">
        <h2>{{ title }}</h2>
      </v-col>
      <v-col>
        <v-btn
          :disabled="
            HasPrerequisiteDefects ||
              this.$store.state.campaigns.editor.start_date == null
          "
          outlined
          @click="editTVPrograms"
        >
          <v-icon class="mr-3">mdi-pencil</v-icon>
          {{ $t('Edit') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="HasPrerequisiteDefects">
      <v-col>
        {{ PrerequisiteInfo.message }}
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if="TVPrograms.length > 0">
        <v-chip-group column active-class="primary--text">
          <v-chip
            v-for="(prg, i) in TVPrograms"
            :key="i"
            :color="TypeSpecificSettings.ChipColor"
            text-color="white"
          >
            {{ prg.title['#text'] }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col v-else>
        {{ TypeSpecificSettings.NoProgramsYetString }}
      </v-col>
    </v-row>

    <v-dialog v-model="dialogTVPrograms" persistent max-width="1000px">
      <v-card class="pa-5">
        <v-card-title class="mb-5">
          <span class="headline">{{ $t('Include TV programs') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="validFormTVPrograms">
            <v-row>
              <v-col cols="5">
                <v-card outlined>
                  <v-text-field
                    v-model="searchString"
                    hide-details="auto"
                    filled
                    placeholder="Quick search"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                  <v-list
                    dense
                    flat
                    height="300px"
                    border="2"
                    class="overflow-y-auto"
                  >
                    <v-list-item-group color="primary">
                      <v-list-item
                        v-for="(item, i) in programs"
                        :key="item.id"
                        @click="listItemClicked(item)"
                      >
                        <v-list-item-avatar>
                          <div
                            class="grey--text lightn-5 title font-weight-bold"
                          >
                            {{ avatarLetter(programs, i) }}
                          </div>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.title['#text']"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-card>
              </v-col>
              <v-col class="ml-5">
                {{ $t('Select the TV programs') }}
                <span class="font-weight-black">
                  {{ $t('you want to') }}
                </span>
                {{ $t('include to the campaign.') }}
                <v-chip-group class="mt-5" column>
                  <v-chip
                    v-for="(prg, i) in TVProgramsEditor"
                    :key="i"
                    close
                    :color="TypeSpecificSettings.ChipColor"
                    text-color="white"
                    @click:close="removeTVProgramHandler(prg)"
                  >
                    {{ prg.title['#text'] }}
                  </v-chip>
                </v-chip-group>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            large
            outlined
            width="150px"
            @click="dialogTVPrograms = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            large
            :disabled="TVProgramsEditorHasChanges"
            width="150px"
            @click="saveTVPrograms"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash'
import { arrayDiff, sureClone } from '@/lib/DataUtils'

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialogTVPrograms: false,
    validFormTVPrograms: false,
    TVProgramsEditor: [],
    search: null
  }),
  computed: {
    TypeSpecificSettings: {
      get() {
        if (this.type == 'included') {
          return {
            ChipColor: '#14d684',
            StoreCollection: 'campaigns/includedTVPrograms',
            StoreUpdateAction: 'campaigns/updateIncludedTVProgramsToEditor',
            NoProgramsYetString: this.$t('No included TV programs set yet')
          }
        } else if (this.type == 'excluded') {
          return {
            ChipColor: '#E65C53',
            StoreCollection: 'campaigns/excludedTVPrograms',
            StoreUpdateAction: 'campaigns/updateExcludedTVProgramsToEditor',
            NoProgramsYetString: this.$t('No excluded TV programs set yet')
          }
        }
        return {
          ChipColor: 'black',
          StoreCollection: '',
          StoreUpdateAction: ''
        }
      }
    },
    PrerequisiteInfo: {
      get() {
        if (this.type == 'excluded') {
          if (
            !this.$store.state.campaigns.editor.settings.daily_schedule ||
            !this.$store.state.campaigns.editor.settings.daily_schedule
              .start_time
          ) {
            return {
              message:
                'You can’t exclude TV programs unless you don’t set a daily schedule.'
            }
          }
        }
        return null
      }
    },
    HasPrerequisiteDefects: {
      get() {
        return this.PrerequisiteInfo != null
      }
    },
    TVPrograms: {
      get() {
        return this.$store.getters[this.TypeSpecificSettings.StoreCollection]
      }
    },
    TVProgramsInDialog: {
      get() {
        return this.TVProgramsEditor
      },
      set(value) {
        this.TVProgramsEditor = value
      }
    },
    TVProgramsEditorHasChanges: {
      get() {
        return _.isEmpty(
          arrayDiff(
            _.map(this.TVPrograms, '@id'),
            _.map(this.TVProgramsInDialog, '@id')
          )
        )
      }
    },
    searchString: {
      get() {
        return this.search
      },
      set(value) {
        this.search = value
      }
    },
    programs: {
      get() {
        let me = this
        return _.filter(this.$store.getters['campaigns/programs'], function(p) {
          if (me.search == null) return true
          return (
            p.title['#text'].toUpperCase().indexOf(me.search.toUpperCase()) !=
            -1
          )
        })
      }
    }
  },
  methods: {
    async confirmEditIfNecessary() {
      let me = this
      if (!me.hasDetailedScheduleEdited) return true
      try {
        await me.$confirm(
          'You have edited detailed schedule. The detailed schedule is reset if the basic schedule options are edited. Do you want to continue?',
          'Confirm edit'
        )
      } catch (err) {
        me.$error(err)
        return false
      }
      return true
    },
    openTVProgramsDialog() {
      let me = this
      me.$store.dispatch('campaigns/loadTVPrograms')
      me.TVProgramsInDialog = sureClone(
        this.$store.getters[me.TypeSpecificSettings.StoreCollection]
      )
      me.dialogTVPrograms = true
    },
    listItemClicked(item) {
      this.TVProgramsInDialog.push(item)
    },
    avatarLetter(collection, index) {
      let firstLetterInCurrentItem = collection[index].title['#text'].substring(
        0,
        1
      )
      if (index == 0) return firstLetterInCurrentItem

      let firstLetterInPreviousItem = collection[index - 1].title[
        '#text'
      ].substring(0, 1)

      return firstLetterInPreviousItem === firstLetterInCurrentItem
        ? ''
        : firstLetterInCurrentItem
    },
    removeTVProgramHandler(program) {
      this.TVProgramsInDialog = _.reject(this.TVProgramsInDialog, {
        title: program.title
      })
    },
    async editTVPrograms() {
      let me = this
      me.$debug('edittiiii')
      if (await me.confirmEditIfNecessary()) {
        me.openTVProgramsDialog()
      }
    },
    async saveTVPrograms() {
      let me = this
      me.$store.dispatch(
        me.TypeSpecificSettings.StoreUpdateAction,
        me.TVProgramsInDialog
      )
      me.dialogTVPrograms = false
    }
  }
}
</script>
