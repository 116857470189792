<template>
  <div>
    <v-row>
      <v-col>
        <v-row style="align-items: center">
          <v-switch
            v-model="is_interactive"
            color="success"
            class="px-3"
          ></v-switch>
          <h2>Campaign is interactive</h2>
        </v-row>
        <p>
          {{
            $t(
              'Interactive campaigns let you interact with the viewer through remote interactions and a SMS message.'
            )
          }}
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
</style>

<script>
export default {
  computed: {
    is_interactive: {
      get() {
        return this.$store.state.campaigns.editor.is_interactive
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.is_interactive', value])
      }
    }
  }
}
</script>
