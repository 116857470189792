<template>
  <div>
    <v-row class="pb-10">
      <v-col>
        <h3 class="h3-bottom">
          {{ $t('Interaction screen graphic') }}
        </h3>
        <p class="preview-margin-bottom">
          {{
            $t(
              'Upload an image that includes e.g. a QR code along with button information. Minimum size for image is 410 x 720 px.'
            )
          }}
        </p>
        <v-row v-if="creative_preview">
          <v-col>
            <v-img
              :src="creative_preview"
              :max-width="200"
              :aspect-ratio="graphic.width / graphic.height"
            ></v-img>
          </v-col>
        </v-row>
        <input
          ref="app_creative"
          type="file"
          class="d-none"
          accept=".png,image/png"
          @change="selectFile('app_creative')"
        />
        <v-btn
          color="primary"
          outlined
          large
          class="upload-button"
          :loading="uploading"
          @click.native="$refs.app_creative.click()"
        >
          {{ $t('Upload image') }}
        </v-btn>
      </v-col>
    </v-row>

    <div>
      <v-row class="px-3" style="align-items: center">
        <v-switch v-model="uses_legal_template" color="success"></v-switch>
        <h3>The campaign can display a legal text.</h3>
      </v-row>
      <div v-if="uses_legal_template"></div>
      <v-row v-if="uses_legal_template">
        <v-col>
          <h3 class="h3-bottom">
            {{ $t('Legal text background') }}
          </h3>
          <p class="preview-margin-bottom">
            {{
              $t(
                'Upload an image to be used as background for the legal text. Minimum size for image is 410 x 720 px.'
              )
            }}
          </p>
          <v-row v-if="legal_preview">
            <v-col>
              <v-img
                :src="legal_preview"
                :max-width="200"
                :aspect-ratio="graphic.width / graphic.height"
              ></v-img>
            </v-col>
          </v-row>
          <input
            ref="app_legal"
            type="file"
            class="d-none"
            accept=".png,image/png"
            @change="selectFile('app_legal')"
          />
          <v-btn
            color="primary"
            outlined
            large
            class="upload-button"
            :loading="uploading"
            @click.native="$refs.app_legal.click()"
          >
            {{ $t('Upload image') }}
          </v-btn>
        </v-col>

        <v-col>
          <h3 class="h3-bottom">
            {{ $t('Legal text') }}
          </h3>
          <p>
            {{
              $t(
                'Write the legal text that will be displayed to the viewer upon request.'
              )
            }}
          </p>
          <p class="margin-bottom">
            {{ $t('Leave empty to use the default legal text.') }}
          </p>
          <v-textarea
            v-model="legal_template"
            height="350"
            :label="$t('Legal text')"
            outlined
          ></v-textarea>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<style lang="sass" scoped>
.h3-bottom
  margin-bottom: 20px
.margin-bottom
  margin-bottom: 40px
.preview-margin-bottom
  margin-bottom: 25px
.upload-button
  margin-top: 15px
</style>
<script>
import _ from 'lodash'

export default {
  data: () => ({
    dialog: false,
    graphic: {
      width: 410,
      height: 720,
      thumb_width: 300
    },
    images: {
      original: null
    },
    uploading: false,
    progress: 0
  }),

  computed: {
    assetsByType() {
      return this.$store.getters['campaigns/editorAssetsByType']
    },
    creative_preview() {
      return _.get(this.assetsByType, '[app-creative-300]._uri', false)
    },
    legal_preview() {
      return _.get(this.assetsByType, '[app-legal-300]._uri', false)
    },
    legal_template: {
      get() {
        return this.$store.state.campaigns.editor.legal_template
      },
      set(value) {
        this.$store.commit('campaigns/set', ['editor.legal_template', value])
      }
    },
    uses_legal_template: {
      get() {
        return this.$store.state.campaigns.editor.uses_legal_template
      },
      set(value) {
        this.$store.commit('campaigns/set', [
          'editor.uses_legal_template',
          value
        ])
      }
    }
  },

  mounted() {
    let me = this
    me.resetUpload()
    // TODO: Remove this debug
    // me.dialog = true
  },

  methods: {
    cancelAndClose() {
      let me = this
      me.dialog = false
      me.resetUpload()
    },

    resetUpload() {
      let me = this
      me.uploading = false
      me.progress = 0
      me.images = {
        original: null,
        vertical: null,
        horizontal: null
      }
    },

    selectFile(key) {
      let me = this
      const input = me.$refs[key]
      if (!input.files || !input.files[0]) {
        return me.$error('Unable to access file')
      }
      me.$debug('filereader', input.files[0])
      const reader = new FileReader()
      reader.onload = () => {
        me.images.original = new Image()
        me.images.original.onload = () => {
          me.validateOriginal(key)
        }
        me.images.original.src = reader.result
      }
      reader.readAsDataURL(input.files[0])
    },
    validateOriginal(key) {
      let me = this
      let original = me.images.original
      try {
        if (
          original.width < 410 ||
          original.height < 720 ||
          Math.round((original.height / original.width) * 100) / 100 !== 1.76
        ) {
          throw new Error(
            `Image dimensions are wrong, image should be ${me.graphic.width}x${me.graphic.height} px minimum or have aspect ratio of 1:1.76.`
          )
        }
      } catch (err) {
        me.$error(err)
        me.images.original = null
      }
      me.$debug('validateOriginal', original.width, original.height)
      me.processAndUpload(key)
    },

    async processAndUpload(key) {
      let me = this
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = me.graphic.width
      canvas.height = me.graphic.height
      ctx.drawImage(me.images.original, 0, 0, canvas.width, canvas.height)

      try {
        me.uploading = true

        me.$debug('processAndUpload')
        const original_interaction = await me.imageDataToBlob(
          ctx.getImageData(0, 0, canvas.width, canvas.height)
        )
        me.$debug('original_interaction', original_interaction)
        me.progress = 20

        // upload original
        me.$debug('processAndUpload - upload original')
        let resizedImage = await me.resize(me.graphic.width)
        await me.$store.dispatch('campaigns/uploadBlob', {
          filename: `${key.replace('_', '-')}-original.png`,
          type: `${key.replace('_', '-')}-original`,
          blob: resizedImage
        })
        me.progress = 50

        // upload thumbnail
        me.$debug('processAndUpload - generate thumb')
        let thumb = await me.resize(me.graphic.thumb_width)
        me.$debug('processAndUpload - upload thumb', thumb)
        await me.$store.dispatch('campaigns/uploadBlob', {
          filename: `${key.replace('_', '-')}-${me.graphic.thumb_width}.png`,
          type: `${key.replace('_', '-')}-${me.graphic.thumb_width}`,
          blob: thumb
        })
        me.progress = 100

        await me.$store.dispatch('campaigns/loadAssets')

        me.$toast({
          type: 'success',
          message: `Interaction image added to campaign`
        })

        me.cancelAndClose()
      } catch (err) {
        me.$error(err)
      }
    },

    async resize(width) {
      let me = this
      let canvas = document.createElement('canvas')
      let ctx = canvas.getContext('2d')

      // -- stepped scaling --
      canvas.width = width // destination canvas size
      canvas.height =
        (canvas.width * me.images.original.height) / me.images.original.width
      ctx.drawImage(me.images.original, 0, 0, canvas.width, canvas.height)
      return await me.imageDataToBlob(
        ctx.getImageData(0, 0, canvas.width, canvas.height)
      )
    },

    async imageDataToBlob(imageData) {
      const canvas = document.createElement('canvas')
      canvas.width = imageData.width
      canvas.height = imageData.height
      const ctx = canvas.getContext('2d')
      ctx.fillStyle = 'black'
      ctx.fill()
      ctx.putImageData(imageData, 0, 0)
      return await new Promise(resolve => canvas.toBlob(resolve, 'image/png'))
    }
  }
}
</script>
